import { formatDateToMDHHmm_or_YYYYMDHHmm } from '@/utils/date/date';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DetailDisplayItem from './DetailDisplayItem';
import DisplayDiffDownTime from './DisplayDiffDownTime';

export type DisplayDownTimeProps = {
  startAt?: Date;
  endAt?: Date;
};

const DisplayDownTime: FC<DisplayDownTimeProps> = ({ startAt, endAt }: DisplayDownTimeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailDisplayItem
        label={t('date.down-time-start-time')}
        value={startAt ? formatDateToMDHHmm_or_YYYYMDHHmm(startAt) : ''}
      />
      <DetailDisplayItem
        label={t('date.down-time-end-time')}
        value={endAt ? formatDateToMDHHmm_or_YYYYMDHHmm(endAt) : ''}
      />
      <DisplayDiffDownTime startAt={startAt} endAt={endAt} />
    </>
  );
};

export default DisplayDownTime;
